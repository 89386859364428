import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostHero from "../components/post-hero"
import NonconformistscurrentFeed from "../components/nonconformistscurrentfeed"
import NonconformistspasteventsFeed from "../components/nonconformistspasteventsfeed"

const NonconformistsHub = () => (

    <Layout>
        <SEO title="Nonconformists Collection" />
    
        <PostHero
            title="Nonconformists Collection"
            description="Global spirit affairs"
            image={require('../images/nonconfirmists.jpg')}
        />

      <div className="hub-wrapper">

        <div className="hub-section">

        <div className="topline-statement">
            <p>The significant impact of distilled spirits on world history, art & culture inclusive of international spirits, distillers, drinks and bartenders</p>        
        </div>
     
            <div className="hub-cards-section">
                <NonconformistscurrentFeed />
            </div>

            <div className="hub-section-title center">
                <h2>Recent Nonconformists Events</h2>
            </div>
            <div className="hub-cards-section">
                <NonconformistspasteventsFeed />
            </div>

         </div>

      </div>

  </Layout>
)

export default NonconformistsHub